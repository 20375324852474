/*===================================================================================*/

/*	CUSTOM CSS STYLES
/*===================================================================================*/

/* Insert your own styles in here! */
#hero h1 {
  font-size: 40px;
}

.active {
  color: #fc575e !important;
}

.tabs-circle-top .tab.active a {
  color: #fff !important;
}

.title-underline {
  display: inline-block;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(72%, transparent),
    color-stop(0, #91ded6)
  ) !important;
  background: linear-gradient(180deg, transparent 72%, #91ded6 0) !important;
  background-size: 100% 87% !important;
  background-repeat: no-repeat;
}

.question {
  font-size: 2.2rem;
  line-height: 4rem;
}

@media (max-width: 415px) and (max-height: 813px) {
  #hero h1 {
    font-size: 29px;
  }
}


@media (min-width: 768px) and (max-height: 1024px) {
  #hero h1 {
    font-size: 40px;
    line-height: 115%;
  }
}
